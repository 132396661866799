//jsxhook

import React, { useContext, ReactNode } from "react"
import { Money } from "@commercetools/platform-sdk/dist/declarations/src/generated/models/common"
import fetch from "isomorphic-unfetch"
import { AppConfig } from "../Config"
import { DataCache } from "./DataCache"

export interface Rates {
  ISK?: number
  GBP?: number
  USD?: number
  DKK?: number
}

interface ExchangeRatesResponse {
  rates: Rates
  base: string
  date: string
}

export class ExchangeRatesResolver {
  private static exchangeRate(
    currencySymbol: string
  ): Promise<ExchangeRatesResponse> {
    const apiKey = AppConfig.exchangeRateApi.key
    return fetch(
      `https://api.exchangeratesapi.io/v1/latest?base=EUR&symbols=${currencySymbol}&access_key=${apiKey}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(response => response.json())
  }

  public fetchExchangeRates = (): Promise<ExchangeRatesResponse> =>
    ExchangeRatesResolver.exchangeRate("ISK,GBP,USD,DKK")
}

export const ExchangeRatesContext = React.createContext<{ rates?: Rates }>({})

export const ExchangeRatesProvider: React.FC<{
  children?: ReactNode
  rates?: Rates | undefined
}> = ({ rates, children }) => {
  return (
    <ExchangeRatesContext.Provider value={{ rates }}>
      {children}
    </ExchangeRatesContext.Provider>
  )
}

function toEuroAmount(money: Money | undefined, toEuroRate: number): Money {
  if (!money || !toEuroRate) {
    return {
      centAmount: 0,
      currencyCode: "EUR"
    }
  }

  if (money.currencyCode === "EUR") {
    return money
  } else if (money.currencyCode === "ISK") {
    return {
      centAmount: Math.round((money.centAmount / toEuroRate) * 100),
      currencyCode: "EUR"
    }
  } else {
    return {
      centAmount: Math.round(money.centAmount / toEuroRate),
      currencyCode: "EUR"
    }
  }
}

export const useExchangeRateContext = () => {
  const { rates } = useContext(ExchangeRatesContext)

  const convertToEuros = (amount: Money): Money => {
    const rate = rates?.[amount.currencyCode]
    return toEuroAmount(amount, rate)
  }

  const convertToEuroString = (amount: Money | undefined): string => {
    const currencyCode = amount?.currencyCode || "EUR"
    const rate = rates?.[currencyCode] || 1

    const result = toEuroAmount(amount, rate).centAmount / 100
    return `${result}`
  }

  return {
    convertToEuros,
    convertToEuroString
  }
}

export const cachingExchangeRatesResolver =
  new DataCache<ExchangeRatesResponse>(
    new ExchangeRatesResolver().fetchExchangeRates
  )
