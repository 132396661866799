import { PrismicCheckoutConfiguration } from "../prismic/PrismicModels"
import React, { ReactNode } from "react"
import { CheckoutConfigContext } from "./CheckoutConfigContext"

export const CheckoutConfigProvider: React.FC<{
  children?: ReactNode
  config: PrismicCheckoutConfiguration
}> = ({ config, children }) => {
  return (
    <CheckoutConfigContext.Provider
      value={{
        config
      }}
    >
      {children}
    </CheckoutConfigContext.Provider>
  )
}
