import { Language } from "@sixty-six-north/i18n"
import React, { ReactNode } from "react"

export const LanguageContext: React.Context<Language> =
  React.createContext<Language>(Language.en)

export const userAcceptLanguageToSupportedLanguage = (
  lang: string
): Language => {
  if (lang === "is") {
    return Language.is
  } else {
    return Language.en
  }
}

export const LanguageProvider: React.FC<{
  children?: ReactNode
  language: Language
}> = ({ language, children }) => {
  return (
    <LanguageContext.Provider value={language}>
      {children}
    </LanguageContext.Provider>
  )
}
