import { useEffect, useState } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AppConfig } from "../Config"

const RECAPTCHA_EVENT_LIST = ["mousemove", "mousedown", "keydown", "touchstart"]

export const GoogleRecaptchaWrapper = props => {
  const { children } = props
  const recaptchaProps = { defer: true, async: true }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={AppConfig.recaptchaSiteKey}
      scriptProps={recaptchaProps}
    >
      {children}
    </GoogleReCaptchaProvider>
  )
}
