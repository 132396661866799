//jsxhook

import React, { useContext, ReactNode } from "react"

export const SearchQueryContext = React.createContext<{ query?: string }>({})

export const useSearchQuery = () => useContext(SearchQueryContext)

export const SearchQueryProvider: React.FC<{
  children?: ReactNode
  query?: string | undefined
}> = ({ query, children }) => {
  return (
    <SearchQueryContext.Provider value={{ query }}>
      {children}
    </SearchQueryContext.Provider>
  )
}
