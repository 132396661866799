import * as Sentry from "@sentry/browser"
import { defer, EMPTY, Observable, Subject, timer } from "rxjs"
import {
  catchError,
  retry,
  shareReplay,
  switchMapTo,
  take,
  takeUntil,
  tap
} from "rxjs/operators"

export class DataCache<T> {
  private readonly millisecondsToLive: number
  private readonly fetchFunction: () => Promise<T>
  private exchangeRates$: Observable<T>
  private stopPolling = new Subject()

  constructor(fetchFunction, minutesToLive = 5) {
    this.millisecondsToLive = minutesToLive * 60 * 1000
    this.fetchFunction = fetchFunction

    const observable = defer<PromiseLike<T>>(() => {
      return fetchFunction()
    }).pipe(
      retry(2),
      catchError(error => {
        Sentry.captureException("Failed to refresh cached data.", error)
        return EMPTY
      })
    )

    this.exchangeRates$ = timer(1, this.millisecondsToLive).pipe(
      switchMapTo(observable),
      shareReplay(1),
      takeUntil(this.stopPolling)
    )
  }

  public latest = (): Observable<T> => this.exchangeRates$.pipe(take(1))
}
