import React, { ReactNode } from "react"
import {
  PrismicDocument,
  PrismicLinkedDocument,
  SimplePrismicDocument
} from "../prismic/PrismicModels"
import { NavigationContext } from "./NavigationContext"

export const NavigationProvider: React.FC<{
  navigation?: SimplePrismicDocument
  navigationTrays?: SimplePrismicDocument[]
  navigationLinkedDocuments?: SimplePrismicDocument[]
  children?: ReactNode
}> = ({ navigation, navigationTrays, navigationLinkedDocuments, children }) => {
  return (
    <NavigationContext.Provider
      value={{
        navigation,
        navigationTrays,
        navigationLinkedDocuments
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}
