import React, { ReactNode } from "react"
import { MessageBarContext } from "./MessageBarContext"
import { GlobalMessageBarItem } from "./MessageBarHooks"

export const MessageBarProvider: React.FC<{
  children?: ReactNode
  messages?: GlobalMessageBarItem[]
}> = ({ messages, children }) => {
  return (
    <MessageBarContext.Provider
      value={{
        messages
      }}
    >
      {children}
    </MessageBarContext.Provider>
  )
}
